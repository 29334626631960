// Кастомизация бутстрапа. Переменные и т.п.

$transition-collapse: height .15s ease;
$gray-950: #181c1f;
$blue-100: #e7f4f9;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1900px
);



.form-wrap{
  max-width: 450px!important;
}




/*
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #6c757d!important;
}*/
