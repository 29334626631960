@import "custom";
@import "~bootstrap/scss/bootstrap";
//@import "~bootstrap-icons/font/bootstrap-icons.css";
//@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");


body.logged{
  padding: 60px 0 0;
}
.form-wrap{
  min-width: 290px;
}
table{
  /*thead{
    th{
      border-bottom: 1px solid $gray-500!important;
    }
  }*/
  input[type="text"]{
    min-width: 60px;
  }
  select{
    min-width: 80px;
  }
  td {
    > a {
      width: 150px;
    }
  }
  &.table-images{
    img{
      border: 1px solid rgba(22,62,84, 0.3);
      display: block;
      width: 150px;
    }
  }
}
td, th {
  vertical-align: middle;
}
.main-sidebar{
  z-index: 10;
  .navbar-wrap{
    position: relative;
    z-index: 11;
    background: $gray-950;
  }
  .navbar-toggler {
    margin-right: .15em;
    box-shadow: none!important;
    &:focus{
      background: $gray-900;
    }
    i{
      margin-top: -1px;
    }
  }
}
.offcanvas-collapse {
  width: 280px;
  position: fixed;
  left: -280px;
  top: 0;
  bottom: 0;
  padding-top: 75px!important;
  background: $gray-900;
  overflow-y: auto;
  transition: all .2s ease;
  &.open{
    left: 0;
    //visibility: visible;
  }
  > ul > li{
    margin-bottom: .25em;
    &.second-wrap>.nav-link{
      &.active{
        background: none;
      }
    }
  }
  li{
    width: 100%;
    //background: $gray-900;
    .nav-link{
      position: relative;
      white-space: nowrap;
      &:hover:not(.active){
        background: $gray-800;
      }
      span{
        margin-left: .375em;
      }
      .arrow{
        position: absolute;
        right: 1em;
        font-size: .75em;
        top: .75em;
      }
    }
    .second-level{
      display: none;
      list-style: none;
      padding: 0;
      overflow: hidden;
      .nav-link{
        font-size: .875rem;
        padding-left: 3.05em;
        border-radius: 0;
        height: 40px;
        &:hover:not(.active) {
          background: $gray-700;
        }
      }
    }
    &.open{
      border-radius: .25em;
      background: $gray-800;
      overflow: hidden;
      .second-level{
        display: block;
      }
    }
  }
}
.user-menu{
  margin: 0 .5em 0 auto;
  .avatar{
    i{
      margin-right: .125em;
    }
  }
}
.jstree-grid-resizable-separator {
  width: 8px!important;
}
.catalog-tree-wrap{
  padding-right: 400px;
  &.columns-2{
    left: 72px;
    right: 0;
  }
}
.tree-sidebar{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 400px;
  background: $gray-200;
  > .tab-content{
    //background: $blue-100;
    overflow-y: auto;
  }
  >.nav-tabs .nav-link.active {
    //background-color: $blue-100;
    border-color: $gray-300 $gray-300 transparent;
  }
  header i{
    margin-right: .5em;
  }
  .node-group{
    max-width: 60px;
  }
}
#main-lang-tabs{
  margin: -.5rem -.5rem 0 -.5rem;
  border-color: $gray-400!important;
  .nav-link{
    border: 1px solid $gray-200;
    &.active{
      background: $white;
      color: $black;
      border-color: $gray-400;
      span{
        display: inline-block;
      }
    }
    span{
      display: none;
      font-size: .75rem;
      vertical-align: text-top;
      &:hover{
        color: $danger;
      }
    }
  }

}
.input-group + .invalid-feedback{
  display: block;
}
#tabs{
  .nav-link{
    position: relative;
    &.changed{
      &:after{
        content: '';
        position: absolute;
        right: .5rem;
        top: .5rem;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: $primary;
      }
    }
    .badge{
      left: 26px;
      position: absolute;
      top: 3px;
      min-width: 20px;
    }
  }
}
#tabs-content{
  > * .tab-content{
    //background: $gray-100;
  }
  .nav-tabs .nav-link.active {
    //background-color: $gray-100;
  }
}
.toast-container{
  z-index: $zindex-tooltip;
  left: 0;
  top: 58px;
}
.badge{
  font-weight: 400;
}
#tab-content-history{
  overflow-y: scroll;
}
.accordion-header{
  position: relative;
  > .btn{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1rem;
    z-index: 3;
    border: none;
    margin: -.25rem -.375rem;
  }
}
.accordion-button{
  //display: block;
  //position: relative;
  overflow: hidden;
  &:focus{
    box-shadow: none;
  }
  &:after{
    height: 1.375rem;
    align-self: flex-end;
    opacity: .5;
  }
  .edited{
    position: relative;
    &:not(.no-input){
      display: inline-block;
      background: $gray-100;
      box-shadow: inset 0 0 0 1px $gray-300;
      margin-top: .25rem;
      padding: .25rem .5rem;
    }
  }
  &:not(.collapsed){
    background: $gray-200 !important;
    color: $black;
    &:focus{
      //box-shadow: none;
      box-shadow: inset 0 -1px 0 rgba(0,0,0,0.13);
    }
    &:after{
      //background-image: none;
      transform: scaleY(-1);
    }
    .edited{
      //background: $white;
      //box-shadow: none;
      &:not(.no-input){
        font-weight: 500;
      }
      &:before{
        content: '';
        position: absolute;
        left: -.5rem;
        top: 50%;
        bottom: -1.25rem;
        width: 2px;
        background: $success;
        z-index: 3;
      }
      &:after{
        content: '';
        position: absolute;
        left: -.5rem;
        top: 50%;
        margin-top: -5px;
        margin-left: -5px;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: $success;
        z-index: 3;
        border: 1px solid $success;
      }
    }
  }
  h6{
    margin-right: .5rem;
  }
  > span{
    display: block;
  }
  .badge{
    height: 20px;
    color: $black;
    display: inline;
    i{
      font-size: .8rem;
    }
    &.lang{
      border: 1px solid $gray-300;
    }
    &:not(.lang){
      i{
        font-size: 1rem;
      }
    }

  }
}
.accordion-body{
  background: $gray-200;
  padding-right: 0!important;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: .5rem;
    top: 0;
    bottom: .5rem;
    width: 2px;
    background: $success;
    z-index: 3;
  }
  .list-group-item{
    background: none;
    cursor: pointer;
    &:after{
      content: '';
      position: absolute;
      left: -5px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: $gray-200;
      z-index: 3;
      border: 1px solid $success;
    }
    &.active{
      background: $white;
      border-color: $gray-200;
      color: $black;
      z-index: auto;
      &:after{
        content: '\f26e';
        font-family: bootstrap-icons;
        width: 18px;
        height: 18px;
        line-height: 18px;
        left: -8px;
        margin-top: -8px;
        color: $white;
        background: $success;
      }
      h6{
        //color: $success;
      }
    }
    &:not(.active):hover{
      background: $gray-100;
      color: $black;
    }
    small{
      display: block;
    }
  }
  .list-group-item-action:hover{
    z-index: auto;
  }
}
.color{
  &-indigo{
    color: $indigo;
  }
}
.btn{
  &-indigo{
    color: $indigo;
    &:hover{
      background-color: $indigo;
      color: $white;
    }
  }
}
.btn-outline{
  &-indigo{
    color: $indigo;
    border-color: $indigo;
    &:hover{
      background-color: $indigo;
      color: $white;
    }
  }
}

.tag{
  display: inline-flex;
  border-radius: $border-radius-sm;
  padding: .25rem .5rem;
  line-height: 1.5;
  border: 1px solid transparent;
  margin: 0 .05rem .35rem 0;
  &-indigo{
    color: $indigo;
    border-color: $indigo;
    .form-check-input{
      &:checked {
        background-color: $indigo;
        border-color: $indigo;
      }
    }
  }
  &-dark{
    color: $dark;
    border-color: $dark;
    .btn{
      &-dark{
        color: $dark;
        background-color: transparent;
        border-color: transparent;
        &:hover{
          background-color: $dark;
          color: $white;
        }
      }
    }
  }
  .btn{
    line-height: 1rem;
    margin: 0 -.2rem auto .25rem;
    padding: .05rem;
    vertical-align: top;
    font-size: 1.25rem;
    i{
      line-height: 1rem;
    }
  }
}
.modal{
  h4{
    i{
      line-height: 1.75rem;
    }
  }
  i{
    line-height: 1.25rem;
    vertical-align: top;
  }
}
i[class*="bi-"]{
  vertical-align: 0.125em;
  margin-bottom: calc(0.125em / 2);
}

/*.warning {
  background-color: #fff3cd !important;
}*/

.warning {
  background-color: $yellow-100 !important;
}
.success {
  background-color: $teal-100 !important;
}
.info {
  background-color: $blue-100 !important;
}
.status-new {
  white-space: nowrap;
  color: $blue-100 !important;
  background-color: $blue-700;
}
.status-info {
  white-space: nowrap;
  color: $blue-700 !important;
  background-color: $cyan-100;
}
.status-warning {
  white-space: nowrap;
  color: $orange-600 !important;
  background-color: $yellow-100;
}
.status-danger {
  white-space: nowrap;
  color: $red-600 !important;
  background-color: $red-100;
}
.status-success {
  white-space: nowrap;
  color: $green-600 !important;
  background-color: $green-100;
}
.status-winning {
  white-space: nowrap;
  color: $green-100 !important;
  background-color: $green-500;
}

@include media-breakpoint-up(md) {
  body.logged{
    padding: 0 0 0 64px;
  }
  .main-sidebar{
    bottom: 0;
    right: auto;
    background: $gray-900;
    width: 64px;
    .navbar-wrap{
      position: static;
      background: none;
    }
  }
  .offcanvas-collapse {
    position: relative;
    width: 100%;
    top: -1em;
    left: 0!important;
    padding-top: 0!important;
    li{
    }
  }
  .user-menu{
    position: fixed;
    bottom: 1em;
    left: 2px;
  }
  .toast-container{
    top: auto;
    left: 64px;
    bottom: 0;
  }

}
.modal-upload-file{
  .image{
    max-width: 300px;
  }
}
.image-brand-min{
  width: auto;
  height: 48px;
}
.image-brand-middle{
  width: 180px;
  height: 80px;
}
.upload-file-draggable{
  height: 180px;
  border: dashed 1px gray;
}
.bg-purple.bg-opacity-25{
  background-color: rgba(162, 107, 229, 0.25) !important;
}

#catalog-tree-new, #catalog-tree-original, #purple-new, #purple-original {
  padding: 4px 4px 4px 0;
  i.jstree-ocl {
    float: left;
  }
  a.jstree-anchor{
    display: flex;
    position: relative;
  }
  .changes-inside:after {
    background: #e1a555;
    border-radius: 8px;
    content: "";
    height: 12px;
    position: absolute;
    right: 12px;
    top: 10px;
    width: 12px;
  }
  .jstree-clicked {
    background: none;
    box-shadow: 0 0 0 2px #6899ff;
    z-index: 1;
  }
  .picked {
    background: $gray-200;
  }

}
#purple-new, #purple-original {
  .bg-purple.bg-opacity-25{
    background-color: rgba(255, 101, 101, 0.25) !important;
  }
}


@include media-breakpoint-between(md, xxxl) {
  .main-sidebar{
    .navbar-brand{
      margin: 0 auto;
    }
  }
  .offcanvas-collapse {
    overflow: visible;
    > ul > li{
      &:hover{
        background: $gray-800;
        border-radius: .25em;
        >.nav-link.active{
          background: none;
        }
      }
      >.nav-link{
        transition: all .3s ease;
        &.active{
          background: $primary;
        }
      }
    }
    li{
      width: auto;
      height: 40px;
      span {
        display: none;
      }
      &:hover{
        span {
          display: inline;
        }
        .nav-link{
          &.active{
            +.second-level{
              display: block;
            }
          }
        }
        .second-level{
          display: block;
        }
      }
      &.open{
        overflow: visible;
        background: none;
        .second-level{
          display: none;
        }
        &:hover{
          .second-level{
            display: block;
          }
        }
      }
      .nav-link{
        .arrow{
          display: none;
        }
      }
      .second-level{
        background: $gray-800;
        margin: -.25em 0 0 56px;
        padding: .25em 0 0;
        border-radius: 0 0 .25em 0;
        .nav-link{
          padding-left: 1em;
          &:not(.active):hover{
            background: $gray-700;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .catalog-tree-wrap {
    padding-right: 500px;
  }
  .tree-sidebar {
    width: 500px;
  }
}

@include media-breakpoint-up(xxxl) {
  body.logged{
    padding: 0 0 0 280px;
  }
  .main-sidebar{
    width: 280px;
  }
  .offcanvas-collapse {
    li{
      span{
        display: inline!important;
      }
    }
  }
  .user-menu{
    bottom: 1.25em;
    left: .5em;
  }
  .toast-container{
    left: 280px;
  }
  .catalog-tree-wrap{
    &.columns-2{
      left: 288px;
    }
  }
}